import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import PreviewRow from '../components/blog/PreviewRow'
import Faqs from '../components/common/FAQ'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import ContactCTA from '../components/cta/ContactCTA'
import GeneralCTA from '../components/cta/GeneralCTA'
import RequestChargepointCTA from '../components/cta/RequestChargepointCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'
class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    // sort blog posts with oldest last
    const orderedPosts = posts.sort((a, b) => {
      return new Date(b.rawDate) - new Date(a.rawDate)
    })

    return (
      <Layout location={this.props.location}>
        <Seo
          title="EV drivers | Download on iOS or Android | Urban Fox"
          description="Learn how Urban Fox can help you charge your electric vehicle with ease. Download our app today."
        />
        <Header filter="EV Drivers" />
        <AppCTA topPadded />
        <RequestChargepointCTA />
        <Faqs filter="EV Drivers" />
        <PreviewRow posts={orderedPosts} title="User guides" />
        <ContactCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query BlogGuideQuery {
    allContentfulBlogPost(
      filter: { tags: { in: ["guide"] } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        author {
          name
          heroImage: image {
            gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1180)
          }
        }
        rawDate: publishDate
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        body {
          raw
        }
        description {
          raw
        }
      }
    }
  }
`
